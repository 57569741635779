
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import Guid from 'common/util/guid';
import draggable from 'vuedraggable';
import Client from 'common/models/client';
import dayjs from 'dayjs';
import { DynamicUiEnumDropdown } from '@lcp/dynamic-ui-typescript';
import axios from 'axios';
import base64Image from 'common/util/base64Image';
import ClientConfiguration from '@/models/client';
import PrintTile from '@/models/printTile';
import * as STORE from 'common/store/storepaths';

@Component({ components: { draggable, DynamicUiEnumDropdown } })
export default class PrintPreview extends Vue {
    @Prop()
        clientId!: Guid;

    loading = false;

    reportTypes = [
        { id: 'full', name: 'Full report' },
        { id: 'custom', name: 'Custom' },
    ];

    selectedReportType = 'full';

    lastPreview?: Blob;

    get clients () {
        return this.$store.getters['clientContext/clients'] as Array<Client>;
    }

    mounted () {
        const memberWebTiles: Array<PrintTile> = [{
            components: ['web-registrations-pie', 'web-registrations'], props: { showCurrent: false }, name: 'Web registrations', color: 'aqua',
        },
        {
            components: ['member-registrations-by-age-pie'], name: 'Web registrations by age', color: 'aqua',
        },
        {
            components: ['member-registrations-by-status'], name: 'Web registrations by status', color: 'aqua',
        },
        {
            components: ['member-registrations-by-location'], name: 'Web registrations by location', color: 'aqua', props: { printing: true },
        },
        {
            components: ['member-logins-pie', 'member-logins'], name: 'Member logins', color: 'aqua',
        },
        {
            components: ['member-logins-by-age-pie', 'member-logins-by-age'], name: 'Member logins by age', color: 'aqua',
        },
        {
            components: ['member-logins-by-status-pie', 'member-logins-by-status'], name: 'Member logins by status', color: 'aqua',
        },
        {
            components: ['page-visits-table', 'page-visits'], name: 'Page visits', color: 'aqua',
        },
        {
            components: ['modeller-visits-by-age-pie', 'modeller-visits-by-age'], name: 'Modeller visits by age', color: 'aqua',
        },
        {
            components: ['modeller-times-by-age-pie', 'modeller-times-by-age'], name: 'Time spent in modeller by  age', color: 'aqua',
        },
        {
            components: ['modeller-interactions-by-age-pie', 'modeller-interactions-by-age'], name: 'Modeller interactions by age', color: 'aqua',
        },
        {
            components: ['data-updates-by-age-pie', 'data-updates-by-age'], name: 'Data updates by age', color: 'aqua',
        },
        {
            components: ['data-updates-by-datatype-pie', 'data-updates-by-datatype'], name: 'Data updates by data type', color: 'aqua',
        },
        {
            components: ['data-updates-by-status-pie', 'data-updates-by-status'], name: 'Data updates by member status', color: 'aqua',
        },
        {
            components: ['member-correspondence-views-totals', 'member-correspondence-views'], name: 'Member correspondence', color: 'aqua',
        },
        {
            components: ['payslip-views-by-status-pie', 'payslip-views-by-status'], name: 'Payslips viewed by member status', color: 'aqua',
        },
        {
            components: ['document-views-by-status-pie', 'document-views-by-status'], name: 'Generic documents viewed by member status', color: 'aqua',
        },
        ];
        const financialTiles: Array<PrintTile> = [{
            components: ['bank-balance'], name: 'Bank balance summary', color: 'darkBlue',
        },
        {
            components: ['bank-balance-over-time'], name: 'Bank balance', color: 'darkBlue',
        },
        {
            components: ['income-expenditure-table', 'income-expenditure'], name: 'Income and expenditure', color: 'darkBlue',
        },
        ];
        if (this.showFinancials) {
            this.fullReportOptions.splice(this.fullReportOptions.length - 3, 0, ...financialTiles);
            this.selectedOptions.splice(this.selectedOptions.length - 3, 0, ...financialTiles);
        }
        if (this.hasMemberWeb) {
            this.fullReportOptions.splice(this.fullReportOptions.length - 3, 0, ...memberWebTiles);
            this.selectedOptions.splice(this.selectedOptions.length - 3, 0, ...memberWebTiles);
        }
        this.fullReportOptions = this.filterTiles(this.fullReportOptions);
        this.selectedOptions = this.filterTiles(this.selectedOptions);
    }

    get clientEntity () {
        switch (this.clientName) {
            case 'Law Debenture Pension Plan':
                return 'Trustee';
            default:
                return 'Trustees';
        }
    }

    get clientName () {
        if (this.$route.path.indexOf('/demo') > -1) return 'Demo Scheme';
        if (this.clientId) {
            const name = this.clients.find((a) => a.clientId === this.clientId)?.name;
            if (name === 'Generali Pension Fund') return 'Assicurazioni Generali SpA (UK Branch) Pension Fund';
            if (name === 'Main Fund ICI') return 'ICI Pension Fund';
            if (name === 'BSI') return 'BSI Retirement Benefits Plan';
            return name;
        }
        return '';
    }

    get client () {
        return (this.$store.getters['clientContext/clients'] as Array<Client>).find((a) => a.clientId === this.clientId);
    }

    get clientConfiguration () {
        return this.$store.getters['clientContext/selectedClientConfiguration'](this.clientId) as ClientConfiguration ?? {};
    }

    get hasMemberWeb () {
        return this.clientConfiguration?.hasMemberWeb;
    }

    get showFinancials () {
        return this.clientConfiguration?.showFinancials;
    }

    get isLive () {
        return window.location.href.indexOf('.lcp.uk.com') > -1;
    }

    formatDate (date: string) {
        return dayjs(date).format('DD MMM YYYY');
    }

    get fromDate () {
        return this.$store.getters['clientContext/fromDate'](this.clientId) as string;
    }

    get toDate () {
        return this.$store.getters['clientContext/toDate'](this.clientId) as string;
    }

    get loadingData () {
        return this.$store.getters['clientStatistics/areAnyLoading'](this.clientId) || false as boolean;
    }

    get printDate () {
        return dayjs(new Date()).format('DD/MM/YYYY HH:mm');
    }

    async needBreak () {
        if (!this.$refs.components) return;
        const ref = this.$refs.components as HTMLElement;
        let lastBreakHeight = 0;
        let page = 0;
        for (let i = 0; i < ref.children.length; i += 1) {
            const el = ref.children[i] as HTMLElement;

            if (ref.children[i].getAttribute('data-index')) {
                el.classList.remove('break');
                el.style.height = '0';
            }
        }
        for (let i = 0; i < ref.children.length; i += 1) {
            const el = ref.children[i] as HTMLElement;
            if (i >= ref.children.length - 1) break;
            const nextEl = ref.children[i + 1] as HTMLElement;
            const pageHeight = 1697;
            if (ref.children[i].getAttribute('data-index')) {
                if (nextEl.offsetTop + nextEl.offsetHeight - lastBreakHeight > pageHeight) {
                    el.classList.add('break');
                    if (page === 1) {
                        el.style.height = `${pageHeight + (page * pageHeight) - el.offsetTop - 13}px`;
                        lastBreakHeight = el.offsetTop + pageHeight + (page * pageHeight) - el.offsetTop - 13;
                    } else {
                        el.style.height = `${pageHeight + (page * pageHeight) - el.offsetTop}px`;
                        lastBreakHeight = el.offsetTop + pageHeight + (page * pageHeight) - el.offsetTop;
                    }
                    page += 1;
                } else {
                    el.classList.remove('break');
                    el.style.height = '0';
                }
            }
        }
    }

    options: Array<PrintTile> = [
    ];

    fullReportOptions: Array<PrintTile> = [
        {
            components: ['completed-pie-sla', 'completed-jobs-sla'], props: { viewByCase: false }, name: 'Cases completed within target', color: 'watermelon',
        },
        {
            components: ['completed-jobs-sla'], props: { viewByCase: true }, name: 'Cases completed within target (by case)', color: 'watermelon',
        },
        {
            components: ['completed-pie-sla'], props: { showTable: true }, name: 'Cases completed within target by case (table)', color: 'watermelon',
        },
        {
            components: ['inprogress-pie', 'inprogress-jobs'], props: { showCurrent: true }, name: 'Current live cases', color: 'watermelon',
        },
        {
            components: ['completed-pie', 'completed-jobs'], name: 'Completed cases', color: 'watermelon',
        },
        {
            components: ['data-scores'], name: 'Data scores', color: 'blue',
        },
        {
            components: ['member-movements'], name: 'Member movements in period', color: 'grapefruit',
        },
        {
            components: [{ id: 'demographics', props: { tableView: true, printing: true } }, { id: 'demographics', props: { printing: true } }], props: { printing: true }, name: 'Current demographics', color: 'grapefruit',
        },
        {
            components: ['member-statistics-tile'], name: 'Current member statistics', color: 'grapefruit',
        },
        {
            components: ['client-calendar'], name: 'Events calendar', color: 'blue', props: { showCurrent: false },
        },
        {
            components: [{ id: 'transfers', props: { viewByValue: true, printing: true } }, { id: 'transfers' }], props: { printing: true }, name: 'Transfer quotations and payments', color: 'grapefruit',
        },
        {
            components: ['member-interactions-pie', 'member-interactions'], name: 'Member interactions', color: 'watermelon',
        },
        {
            components: ['feedback-score', 'div'], props: { printing: true }, name: 'Member feedback', color: 'blue',
        },
        {
            components: ['contact-tile'], name: 'Your team', color: 'lilac',
        },
        {
            components: ['news-items', 'div'], props: { printing: true }, name: 'Pensions news', color: 'grey',
        },
    ];

    selectedOptions = [...this.fullReportOptions];

    get formattedOptions () {
        const rows: Array<{ component: string | { componentId: string; props: Record<string, unknown>}}> = [];
        let currentRow: Array<{ component: string | { componentId: string; props: Record<string, unknown>}}> = [];
        this.selectedOptions.forEach((option) => {
            if (currentRow.length && option.components.length > 1) {
                rows.push([...currentRow] as never);
                currentRow = [];
            }
            option.components.forEach((component: unknown) => {
                if (currentRow.length === 2) {
                    rows.push([...currentRow] as never);
                    currentRow = [];
                }
                currentRow.push({ ...option, component } as never);
            });
        });
        rows.push(currentRow as never);
        console.log(rows);
        return rows;
    }

    print () {
        if (!this.lastPreview) {
            return;
        }

        const url = URL.createObjectURL(this.lastPreview);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.clientName} - ${dayjs(new Date()).format('YYYY-MM-DD HHmm')}`;
        a.click();
    }

    @Watch('selectedReportType')
    selectedReportTypeChanged () {
        if (this.selectedReportType === 'full') {
            this.selectedOptions = this.filterTiles([...this.fullReportOptions]);
            this.options = [];
        }
        if (this.selectedReportType === 'custom') {
            this.selectedOptions = [];
            this.options = this.filterTiles([...this.fullReportOptions].sort((a, b) => (a.name > b.name ? 1 : -1))) as never;
        }
    }

    @Watch('selectedOptions', { immediate: true })
    @Watch('loadingData')
    async optionsChanged () {
        if (this.loadingData) return;
        await this.$nextTick();
        this.loading = true;
        const styles: Array<string> = [];
        for (let i = 0; i < document.styleSheets.length; i += 1) {
            try {
                for (let j = 0; j < document.styleSheets[i].cssRules.length; j += 1) {
                    styles.push(document.styleSheets[i].cssRules[j].cssText);
                }
            } catch (ex) {
                console.log(ex);
            }
        }

        const els = (this.$refs.printPreview as HTMLElement).querySelectorAll('img');
        for (let i = 0; i < els.length; i += 1) {
            const image = els[i];
            if (image.src.indexOf('data:') === -1) {
            // eslint-disable-next-line no-await-in-loop
                await base64Image(image.src).then((result) => {
                    image.src = result as string;
                }).catch((ex) => {
                    console.log(ex);
                });
            }
        }

        window.setTimeout(async () => {
            await this.needBreak();
            axios.post('https://printpdf.lcp.uk.com/api/pdf', {
                html: `<html><head><style>${styles.join('\r\n').replaceAll('Montserrat', 'Arial')}</style></head><body>${((this.$refs.printPreview as HTMLElement).parentElement as HTMLElement).innerHTML.replaceAll('Montserrat', 'Arial')}</body></html>`,
                footer: `<div id="footer-template" style="display: flex; position: relative; z-index: 999; overflow: visible; justify-content: space-between; font-size:8px; !important; color:#808080; padding-left:10px; padding-right: 10px; width: 100%">
                    <span style="overflow: visible">${this.printDate}</span>
                    <div class="pages">
                        Page <b><span class="pageNumber"></span></b> of
                        <b><span class="totalPages"></span></b>
                    </div>
                </div>`,
                width: 1248,
                height: 1729,
                margin: {
                    bottom: 32,
                    left: 24,
                    right: 24,
                    top: 32,
                },
            }, { responseType: 'arraybuffer' })
                .then((result) => {
                    const blob = new Blob([result.data], { type: 'application/pdf' });
                    const file = window.URL.createObjectURL(blob);
                    this.lastPreview = blob;
                    (this.$refs.iframe as HTMLIFrameElement).src = `${file}#toolbar=0&navpanes=0`;
                    this.loading = false;
                });
        }, 4500);
    }

    get webRegistrationBySummaryEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webregistrationbysummary.enabled'] === 'true';
    }

    get webRegistrationByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webregistrationbytime.enabled'] === 'true';
    }

    get webRegistrationByLocationEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webregistrationbylocation.enabled'] === 'true';
    }

    get webRegistrationByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webregistrationbystatus.enabled'] === 'true';
    }

    get webRegistrationByAgeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webregistrationbyage.enabled'] === 'true';
    }

    get memberLoginEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.memberlogin.enabled'] === 'true';
    }

    get memberLoginByAgeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.memberloginbyage.enabled'] === 'true';
    }

    get memberLoginByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.memberloginbystatus.enabled'] === 'true';
    }

    get pageVisitsEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.pagevisits.enabled'] === 'true';
    }

    get pageVisitsByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.pagevisitsbytime.enabled'] === 'true';
    }

    get modellerVisitsByAgeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.modellervisitsbyage.enabled'] === 'true';
    }

    get modellerVisitsByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.modellervisitsbytime.enabled'] === 'true';
    }

    get modellerTimesByAgeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.modellertimesbyage.enabled'] === 'true';
    }

    get modellerTimesByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.modellertimesbytime.enabled'] === 'true';
    }

    get modellerInteractionsByAgeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.modellerinteractionsbyage.enabled'] === 'true';
    }

    get modellerInteractionsByPageEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.modellerinteractionsbypage.enabled'] === 'true';
    }

    get dateUpdatesByAgeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.dateupdatesbyage.enabled'] === 'true';
    }

    get dateUpdatesByAgeByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.dateupdatesbyage.enabled'] === 'true';
    }

    get dateUpdatesByDataTypeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.dateupdatesbydatatype.enabled'] === 'true';
    }

    get dateUpdatesByDataTypeByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.dateupdatesbyagebytime.enabled'] === 'true';
    }

    get dateUpdatesByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.dateupdatesbystatus.enabled'] === 'true';
    }

    get dateUpdatesByStatusByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.dateupdatesbystatusbytime.enabled'] === 'true';
    }

    get memberCorrespondenceEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.membercorrespondence.enabled'] === 'true';
    }

    get payslipViewsVisible () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.payslipsviews.enabled'] === 'true';
    }

    get payslipViewsByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.payslipsviewsbystatus.enabled'] === 'true';
    }

    get payslipViewsByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.payslipsviewsbytime.enabled'] === 'true';
    }

    get correspondenceViewsByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.correspondenceviewsbystatus.enabled'] === 'true';
    }

    get correspondenceViewsByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.correspondenceviewsbytime.enabled'] === 'true';
    }

    get documentViewsByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.documentviewsbystatus.enabled'] === 'true';
    }

    get documentViewsByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.documentviewsbytime.enabled'] === 'true';
    }

    get webFormsViewsByStatusEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webformviewsbystatus.enabled'] === 'true';
    }

    get webFormsViewsByTimeEnabled () {
        return this.$store.getters[STORE.GET_CLIENT_WEB_SITE_CONFIGURATION]['clientweb.memberactivity.webformviewsbytime.enabled'] === 'true';
    }

    get showModeller () {
        return this.clientConfiguration?.showModeller;
    }

    get showPayslips () {
        return this.clientConfiguration?.showPayslips;
    }

    get disabledPrintTiles () {
        return [
            this.webRegistrationBySummaryEnabled ? '' : 'web-registrations-pie',
            this.webRegistrationByTimeEnabled ? '' : 'web-registrations',
            this.webRegistrationByLocationEnabled ? '' : 'member-registrations-by-location',
            this.webRegistrationByStatusEnabled ? '' : 'member-registrations-by-status',
            this.webRegistrationByAgeEnabled ? '' : 'member-registrations-by-age-pie',
            this.memberLoginEnabled ? '' : 'member-logins-pie',
            this.memberLoginEnabled ? '' : 'member-logins',
            this.memberLoginByAgeEnabled ? '' : 'member-logins-by-age-pie',
            this.memberLoginByAgeEnabled ? '' : 'member-logins-by-age',
            this.memberLoginByStatusEnabled ? '' : 'member-logins-by-status-pie',
            this.memberLoginByStatusEnabled ? '' : 'member-logins-by-status',
            this.pageVisitsEnabled ? '' : 'page-visits-table',
            this.pageVisitsByTimeEnabled ? '' : 'page-visits',
            this.modellerTimesByAgeEnabled ? '' : 'modeller-times-by-age-pie',
            this.modellerTimesByTimeEnabled ? '' : 'modeller-times-by-age',
            this.modellerInteractionsByAgeEnabled ? '' : 'modeller-interactions-by-age-pie',
            this.modellerInteractionsByPageEnabled ? '' : 'modeller-interactions-by-age',
            this.dateUpdatesByAgeEnabled ? '' : 'data-updates-by-age-pie',
            this.dateUpdatesByAgeByTimeEnabled ? '' : 'data-updates-by-age',
            this.dateUpdatesByDataTypeEnabled ? '' : 'data-updates-by-datatype-pie',
            this.dateUpdatesByDataTypeByTimeEnabled ? '' : 'data-updates-by-datatype',
            this.dateUpdatesByStatusEnabled ? '' : 'data-updates-by-status-pie',
            this.dateUpdatesByStatusByTimeEnabled ? '' : 'data-updates-by-status',
            this.memberCorrespondenceEnabled ? '' : 'member-correspondence-views-totals',
            this.memberCorrespondenceEnabled ? '' : 'member-correspondence-views',
            this.showPayslips && this.payslipViewsByStatusEnabled ? '' : 'payslip-views-by-status-pie',
            this.showPayslips && this.payslipViewsByTimeEnabled ? '' : 'payslip-views-by-status',
            this.correspondenceViewsByStatusEnabled ? '' : 'correspondence-views-by-status-pie',
            this.correspondenceViewsByTimeEnabled ? '' : 'correspondence-views-by-status',
            this.documentViewsByStatusEnabled ? '' : 'document-views-by-status-pie',
            this.documentViewsByTimeEnabled ? '' : 'document-views-by-status',
            this.webFormsViewsByStatusEnabled ? '' : 'web-form-views-by-status-pie',
            this.webFormsViewsByTimeEnabled ? '' : 'web-form-views-by-status',
            this.showModeller ? '' : 'modeller-visits-by-age-pie',
            this.showModeller ? '' : 'modeller-visits-by-age',
            this.showModeller ? '' : 'modeller-times-by-age-pie',
            this.showModeller ? '' : 'modeller-times-by-age',
            this.showModeller ? '' : 'modeller-interactions-by-age-pie',
            this.showModeller ? '' : 'modeller-interactions-by-age',
            this.modellerVisitsByAgeEnabled ? '' : 'modeller-visits-by-age-pie',
            this.modellerVisitsByTimeEnabled ? '' : 'modeller-visits-by-age',
            this.showFinancials ? '' : 'bank-balance-over-time',
            this.showFinancials ? '' : 'bank-balance',
            this.showFinancials ? '' : 'income-expenditure',
            this.showFinancials ? '' : 'income-expenditure-table',
        ];
    }

    filterTiles (tiles: Array<PrintTile>) {
        tiles.forEach((tile) => {
            tile.components.forEach((component, i) => {
                if (typeof component === 'string' && this.disabledPrintTiles.includes(component)) {
                    tile.components.splice(i, 1);
                    return;
                }
                if (this.disabledPrintTiles.includes((component as { id: string; props?: Record<string, unknown> }).id)) {
                    tile.components.splice(i, 1);
                }
            });
        });
        return tiles.filter((a) => a.components.length);
    }
}
